import React, { Fragment, useCallback, useEffect, useState } from 'react';

//shared component
import { API_CONFIG } from '../../../shared/constants/constant';

//service configuration
import httpService from '../../../shared/services/http.service';

//interfaces
import { IPageData, Message } from '../interface/chatbot';

//component
import InfiniteScroll from 'react-infinite-scroll-component';
import botProfile from '../../../assets/images/bot-icon.png';
import { AttachmentIcon } from '../../../shared/icons/icon';
import ContentContainer from '../../header/container';
// import image_2 from '../../../assets/images/image (39).png';
// import image_3 from '../../../assets/images/switzerland.jpeg';
// import image_4 from '../../../assets/images/pexels-photo-1371360.jpeg';

const image_1 = require('../../../assets/images/noryhern.jpeg');
const image_2 = require('../../../assets/images/image (39).png');
const image_3 = require('../../../assets/images/switzerland.jpeg');
const image_4 = require('../../../assets/images/pexels-photo-1371360.jpeg');

interface IProps {
  width?: string;
  height?: string;
  titleColor?: string;
  backgroundColor?: string;
  boxShadow?: string;
}

const Chatbot: React.FC<IProps> = ({ backgroundColor = 'bg-white', boxShadow = 'shadow-lg' }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [pageData, setPageData] = useState<IPageData>({} as IPageData);
  const [typeLoading, setTypeLoading] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAttachMent, setIsAttachMent] = useState(false);
  const [selectedImage, setSelectedImage] = useState({ image: '', file_id: '' });

  const getPreviousMessages = useCallback(
    async (id?: string) => {
      const params = { threadId: localStorage.getItem('threadId') as string, after: id, limit: 10 };
      try {
        setLoading(true);
        if (localStorage.getItem('threadId') as string) {
          const data = await httpService.get(`${API_CONFIG.path.message}/list`, params);
          setMessages([...messages, ...data.transformedResponse]);
          setPageData({ before: data.firstId, after: data.lastId, hasMore: data.hasMore });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    [messages]
  );

  useEffect(() => {
    getPreviousMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPreviousMessages]);

  const askQuestion = useCallback(
    async (messageText: string, queType = 'text') => {
      const storedThreadId = localStorage.getItem('threadId');
      let data: Message[] = [];

      setSelectedImage({ image: '', file_id: '' });
      setTypeLoading(true);

      try {
        const baseContent = selectedImage.image
          ? [
              { type: 'image_file', image_file: { file_id: selectedImage.file_id } },
              { type: queType, text: messageText || '' },
            ]
          : [{ type: queType, text: messageText }];

        const params = {
          content: baseContent,
          ...(storedThreadId && { threadId: storedThreadId }), // Include threadId if it exists
        };

        data = await httpService.post(`${API_CONFIG.path.askMessage}`, params);

        if (!storedThreadId && data[0]?.threadId) {
          localStorage.setItem('threadId', data[0].threadId);
        }

        setMessages([...Object.values(data)]);
        setTypeLoading(false);
      } catch (error) {
        console.error(error);
        setTypeLoading(false);
      }
    },
    [selectedImage.file_id, selectedImage.image]
  );

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessageText(e.target.value);
  };

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setMessages([
        {
          role: 'user',
          threadId: '',
          content: [
            {
              type: 'text',
              text: { value: messageText },
            },
          ],
        },
        ...messages,
      ]);
      setMessageText('');
      askQuestion(messageText);
    },
    [askQuestion, messageText, messages]
  );

  const ImageArr = {
    'file-o7JrnQ27qhxvyeEYQ5SbV4La': image_1,
    'file-fvGFcZTkFFp1lFdY0l7Odhdk': image_2,
    'file-xvyBzE7UtkVCMXYEKKHdqBBD': image_3,
    'file-bC41029PXfgSpZhs9T5BqZHs': image_4,
  };

  return (
    <ContentContainer>
      <div className="relative h-[100vh]">
        <div className="pt-[120px] w-[calc(100%-450px)]">
          <h1 className="title mb-[20px]">Your Own Private Social Media Content Manager</h1>
          <p className="description mb-[50px]">
            {' '}
            Engage your audience with a private AI chatbot. Deliver personalized, real-time interactions that enhance
            user engagement and streamline communication for your Instagram and other social media post. Perfect for
            businesses, educators, and content creators. Try it today!{' '}
          </p>
        </div>
        <div
          className={`w-full absolute right-0 bottom-[20px] rounded-[16px] max-w-[400px] flex flex-col ${boxShadow} rounded-t-lg`}
        >
          <div
            className={`flex justify-between p-4 text-center rounded-[16px] font-space rounded-br-none rounded-bl-none shadow-md bg-[#25ADD0]`}
          >
            <div>
              <h1 className={`text-start text-white font-bold text-3xl`}>Need any help?</h1>
              <p className="text-start mt-3.5 font-semibold text-xl text-black">We’re just a message away!</p>
            </div>
            <div className="w-[62px] h-[64px] flex self-end">
              <img src={botProfile} alt="bot" className="w-full h-full object-cover" />
            </div>
          </div>
          <div className={`flex flex-col ${backgroundColor} h-full`}>
            <div
              id="message-list"
              className="p-4 infinite-scroll-component relative max-h-[400px] h-[calc(65vh-120px)] overflow-auto flex flex-col-reverse"
            >
              <InfiniteScroll
                dataLength={messages.length}
                next={() => getPreviousMessages(pageData.after)}
                hasMore={pageData.hasMore}
                loader={circularLoader()}
                inverse={true}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                scrollableTarget="message-list"
                scrollThreshold={1}
              >
                {typeLoading && (
                  <div className="flex">
                    <div className="w-[28px] h-[32px] flex self-end mr-[10px]">
                      <img src={botProfile} alt="bot" className="w-full h-full object-cover" />
                    </div>
                    <div className="bg-[#F2F2F2] self-start rounded-[10px] p-2 w-auto">
                      <div className="loader" />
                    </div>
                  </div>
                )}
                {messages.length > 0 &&
                  messages.map((message, index) => (
                    <div
                      key={index}
                      className={`flex ${
                        message.role === 'user' ? 'self-end flex-row-reverse' : ''
                      } font-space mb-[16px]`}
                    >
                      {message.role === 'assistant' && (
                        <div className="w-[28px] h-[32px] flex self-end mr-[10px]">
                          <img src={botProfile} alt="bot" className="object-cover" />
                        </div>
                      )}
                      <div
                        className={`message ${
                          message.role === 'user' ? 'text-white bg-[#00A5CB] self-end' : 'bg-[#F2F2F2] self-start'
                        } p-2 rounded-[10px] max-w-64 text-black`}
                      >
                        {message.content[0].type === 'text' ? (
                          <p className="break-words font-space text-[15px] font-regular">
                            {message.content[0].text?.value}
                          </p>
                        ) : (
                          <div>
                            {message.content.map(({ type, imageFile, text }: any, index: number) => (
                              <Fragment key={index}>
                                {type === 'text' ? (
                                  <p className="break-words font-space text-[15px] font-regular w-[150px]">
                                    {text?.value}
                                  </p>
                                ) : (
                                  <div className="w-[40px] h-[40px] flex justify-end">
                                    <img
                                      className="rounded-md shadow-m h-full w-full text-right"
                                      src={ImageArr[imageFile.fileId as keyof typeof ImageArr] || selectedImage.image}
                                      alt="selected"
                                    />
                                  </div>
                                )}
                              </Fragment>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                {messages.length === 0 && !loading && (
                  <div className="flex">
                    <div className="w-6 h-6 flex self-end mr-1.5">
                      <img src={botProfile} alt="bot" className="w-full h-full object-cover" />
                    </div>
                    <div className="bg-[#F2F2F2] self-start rounded-[10px] w-auto p-2">
                      <p className="text-black font-space text-[15px] font-regular">
                        Welcome, Pick the photo from your IG gallery?
                      </p>
                    </div>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="input-container border-t-[1px] flex justify-items-center items-center p-[10px] bg-white font-space rounded-bl-[16px] rounded-br-[16px]"
          >
            <div className="relative w-full">
              {selectedImage.image && (
                <div className="w-[40px] h-[40px] absolute">
                  <img className="rounded-md shadow-m h-full w-full" src={selectedImage.image} alt="selected" />
                </div>
              )}
              <input
                style={{ textIndent: selectedImage.image ? '40px' : '0' }}
                type="text"
                placeholder="Type your message..."
                className="input-box flex-1 p-2 border border-gray-300 rounded-lg border-none outline-none w-full"
                value={messageText}
                onChange={handleTextChange}
              />
            </div>
            <div className="cursor-pointer select-none" onClick={() => setIsAttachMent(!isAttachMent)}>
              <AttachmentIcon />
            </div>
            {isAttachMent && (
              <div
                className="bg-[#F2F2F2] w-[300px] max-h-fit absolute top-[44%] right-2 p-4 rounded-2xl transition-all"
                style={{
                  boxShadow:
                    'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), 6px 6px 20px 4px rgb(0 0 0 / 0.25)',
                }}
              >
                <div className="flex justify-between flex-wrap gap-1">
                  {Object.entries(ImageArr).map(([file_id, image]) => (
                    <div
                      key={file_id}
                      className="w-[calc(50%-2px)] cursor-pointer"
                      onClick={() => {
                        setSelectedImage({ image, file_id });
                        setIsAttachMent(false);
                      }}
                    >
                      <img src={image} alt="bot-img" className="w-full h-full" />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </ContentContainer>
  );
};

const circularLoader = () => (
  <div className="flex justify-center">
    <div className="circular-loader"></div>
  </div>
);

export default Chatbot;
