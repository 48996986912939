import React from 'react'

import { RedirectLink } from '../../shared/constants/constant'

import ContentContainer from './container'
import logo from '../../assets/images/st-logo.png'
import globe from '../../assets/images/globe.svg'

const Header = () => {
    return (
        <ContentContainer>
            <div className="header z-[1]">
                <div className="header-left">
                    <a href={RedirectLink} rel="noreferrer">
                        <img className="logo" src={logo} alt="website" />
                    </a>
                </div>
                <div className="header-right">
                    <a className="visit-link" href="https://scaletechsolutions.ai/" target="_blank" rel="noreferrer">
                        <img src={globe} alt="website" />
                        Visit Scaletech
                    </a>
                </div>
            </div>
        </ContentContainer >
    )
}

export default Header   