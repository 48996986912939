import React from 'react';
import Chatbot from './feature/chatbot/container/chatbot';
import Header from './feature/header/header';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Chatbot />
    </div>
  );
};

export default App;
