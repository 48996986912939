import queryString from 'query-string';
import { QueryParameters } from '../interface';
import isEmpty from 'lodash/isEmpty';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const API_KEY = process.env.REACT_APP_API_KEY;

export const RedirectLink = process.env.REACT_APP_REDIRECT_LINK;

export const getUrl = (url: string, params: QueryParameters = {}): string => {
  if (!url.includes('https')) {
    let urlString = `${API_CONFIG.baseUrl}/${url}`;
    // let urlString = `http://192.168.1.91:3000/api/${url}`
    if (params && !isEmpty(params)) {
      urlString += `?${queryString.stringify(params)}`;
    }
    return urlString;
  }

  return url;
};

export const API_CONFIG = {
  baseUrl: BASE_URL,
  path: {
    message: 'message',
    chatBoat: 'chatbot',
    askMessage: 'message/describe-content',
  },
};
